<template>
    <div class="col-6">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="itemSpecs"
                        table-id="itemSpecsTable"
                        style="padding-top: 2px !important;"
                        @add-action="openCreateItemSpecDialog()"
                    ></ExportTable>
                    <HelpPopUpV2 help-page-component="ItemSpecsListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.itemSpecs"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allCleanedSpecs"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="itemSpecTable"
            item-key="Spec.id"
            sort-by="Spec.version"
            sort-desc
        >
            <template v-slot:item.index="{ item }">
                <div class="text-center align-center">{{ allCleanedSpecs.indexOf(item) + 1}}</div>
            </template>
            <template v-slot:item.Spec.title="{ item }">
                <div class="font-weight-bold align-center">{{ item.Spec.title }}</div>
            </template>
            <template v-slot:item.Spec.mc_id="{ item }">
                <div class="align-center">{{ getMoistureContent(item.Spec.mc_id) }}</div>
            </template>
            <template v-slot:item.Spec.version="{ item }">
                <div class="align-center">{{ item.Spec.version }}</div>
            </template>
            <template v-slot:item.Spec.productgroups="{ item, index }">
                <v-chip class="mr-1 mb-1" v-for="product in item.Spec.productGroupNames" small :key="index">{{ product }}</v-chip>
            </template>
            <template v-slot:item.Spec.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditItemSpecDialog(item.Spec.id, item.Spec.title)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateItemSpec') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteItemSpec(item.Spec.id, item.Spec.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteItemSpec') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <AddItemSpec
            :item-spec-id="selectedItemSpecId"
            :item-spec-name="selectedItemSpecName"
            :dialog.sync="editDialog"
            :page-key="pageKey"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="itemSpecUpdated"
            @create-done="itemSpecCreated"
        ></AddItemSpec>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
const AddItemSpec = () => import('Components/Appic/AddItemSpec')
const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "ItemSpecsListing",
    components: { AddItemSpec, ExportTable, HelpPopUpV2 },
    props: ['addDialog'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                itemSpecs: false
            },
            searchField: null,
            searchTerm: null,
            selectedItemSpecId: null,
            selectedItemSpecName: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalItemSpec: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('spec', {
            allCleanedSpecs: 'allCleanedSpecs'
        }),
        headers () {
            return [
                // {
                //     id: 0,
                //     text: this.$t('message.no'),
                //     value: 'index',
                //     class: 'light-green lighten-3 pa-1 width-1-pct',
                //     sortable: false
                // },
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Spec.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.specs') + ' (' + this.$t('message.ncr') + ')',
                    value: 'Spec.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.productGroups') + ' (' + this.$t('message.appic') + ')',
                    value: 'Spec.productgroups',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                // {
                //     id: 4,
                //     text: this.$t('message.version'),
                //     value: 'Spec.version',
                //     class: 'light-green lighten-3 pa-1 width-10-pct text-center',
                //     sortable: true
                // },
            ]
        },
        pageKey() {
            return Math.floor(Math.random() * 100)
        }
    },
    methods: {
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup',{
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('spec', {
            deleteSpecById: 'deleteSpecById',
            getAllCleanedSpecs: 'getAllCleanedSpecs',
            resetState: 'resetState'
        }),
        async deleteItemSpec(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteItemSpec') + ' ' +  title, this.$t('message.confirmations.continueItemSpecCancelAction'), {color: 'orange'})){
                this.deleteSpecById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.itemSpecDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.resetState()
                        this.getAllCleanedSpecs()
                    } else {
                        this.$toast.error(this.$t('message.errors.itemSpecNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        getProuductGroupText(productId) {
            let product = this.allProductGroups.find(p => {
                return p.ProductGroup.id == productId
            })
            if(product) {
                return product.ProductGroup.title
            } else {
                return ''
            }
        },
        getMoistureContent(moistureContentId) {
            let mcObj = this.allMoistureContent.find(mc => mc.Mc.id == moistureContentId)
            if(mcObj){
                return mcObj.Mc.title
            }
            return ''
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateItemSpecDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditItemSpecDialog (itemSpecId, itemSpecName) {
            this.selectedItemSpecId = itemSpecId
            this.selectedItemSpecName = itemSpecName
            this.updateMode = true
            this.editDialog = true
        },
        itemSpecCreated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        itemSpecUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateItemSpecDialog()
            }
        }
    },
    created(){
        this.resetState()
        if(this.allMoistureContent.length == 0) this.getAllMoistureContent()
        if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.v-data-table-header th {
    white-space: nowrap;
}
</style>